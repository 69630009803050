export const columnDefsYieldRMReisebasis = [
  {
    sortable: true,
    field: 'rm',
    headerName: 'RM',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    width: 100,
    autoGroupColumnDef: {
      headerName: 'Name',
    },
  },
  {
    sortable: true,
    field: 'prioritaet',
    headerName: 'Prio',
    cellRenderer: 'CellRendererPrioritaet',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    width: 90,
  },
  { sortable: true, field: 'reise', headerName: 'Reise', width: 110, sort: 'asc', pinned: 'left' },
  { sortable: true, field: 'abfh', headerName: 'ABFH', width: 85, pinned: 'left' },
  {
    sortable: true,
    field: 'kpiVorBearbeitung',
    headerName: ' KPI vor Bearbeitung',
    width: 130,
    headerComponentParams: {
      withBreakpoints: true,
    },
    aggFunc: params => {
      if (
        params.values.length > 0 &&
        params.values[0].hasOwnProperty('aggCountVerfuegbarLe200') &&
        params.values[0].hasOwnProperty('aggCountGesamt')
      ) {
        const aggCountVerfuegbarLe200 = params.values.reduce(
          (acc, curr) => (acc += curr.aggCountVerfuegbarLe200),
          0
        );
        const aggCountGesamt = params.values.reduce((acc, curr) => (acc += curr.aggCountGesamt), 0);
        const roundedKpiVorBearbeitung = Math.round((aggCountVerfuegbarLe200 / aggCountGesamt) * 100);
        return {
          toString: () => roundedKpiVorBearbeitung + '%',
          aggCountVerfuegbarLe200,
          aggCountGesamt,
        };
      } else {
        const aggCountVerfuegbarLe200 = params.values.reduce(
          (acc, curr) => (acc += curr.countVerfuegbarLe200),
          0
        );
        const aggCountGesamt = params.values.reduce((acc, curr) => (acc += curr.countGesamt), 0);
        const roundedKpiVorBearbeitung = Math.round((aggCountVerfuegbarLe200 / aggCountGesamt) * 100);
        return {
          toString: () => roundedKpiVorBearbeitung + '%',
          aggCountVerfuegbarLe200,
          aggCountGesamt,
        };
      }
    },
    valueFormatter: params =>
      !isNaN(params.value.kpiVorBearbeitung) ? params.value.kpiVorBearbeitung + '%' : null,
    valueGetter: params => params.data,
  },
  {
    sortable: true,
    field: 'kpiNachBearbeitung',
    headerName: 'KPI nach Bearbeitung',
    width: 130,
    aggFunc: params => {
      if (
        params.values.length > 0 &&
        params.values[0].hasOwnProperty('aggCountAccepted') &&
        params.values[0].hasOwnProperty('aggCountVerfuegbarkeitGeschaffen') &&
        params.values[0].hasOwnProperty('aggCountAufpreisLe200WithoutBearbeitungstatus') &&
        params.values[0].hasOwnProperty('aggCountGesamt')
      ) {
        const aggCountAccepted = params.values.reduce((acc, curr) => (acc += curr.aggCountAccepted), 0);
        const aggCountVerfuegbarkeitGeschaffen = params.values.reduce(
          (acc, curr) => (acc += curr.aggCountVerfuegbarkeitGeschaffen),
          0
        );
        const aggCountAufpreisLe200WithoutBearbeitungstatus = params.values.reduce(
          (acc, curr) => (acc += curr.aggCountAufpreisLe200WithoutBearbeitungstatus),
          0
        );
        const aggCountGesamt = params.values.reduce((acc, curr) => (acc += curr.aggCountGesamt), 0);
        const roundedKpiNachBearbeitung = Math.round(
          ((aggCountAccepted +
            aggCountVerfuegbarkeitGeschaffen +
            aggCountAufpreisLe200WithoutBearbeitungstatus) /
            aggCountGesamt) *
            100
        );
        return {
          toString: () => roundedKpiNachBearbeitung + '%',
        };
      } else {
        const aggCountAccepted = params.values.reduce((acc, curr) => (acc += curr.countAccepted), 0);
        const aggCountVerfuegbarkeitGeschaffen = params.values.reduce(
          (acc, curr) => (acc += curr.countVerfuegbarkeitGeschaffen),
          0
        );
        const aggCountAufpreisLe200WithoutBearbeitungstatus = params.values.reduce(
          (acc, curr) => (acc += curr.countAufpreisLe200WithoutBearbeitungstatus),
          0
        );
        const aggCountGesamt = params.values.reduce((acc, curr) => (acc += curr.countGesamt), 0);
        const roundedKpiNachBearbeitung = Math.round(
          ((aggCountAccepted +
            aggCountVerfuegbarkeitGeschaffen +
            aggCountAufpreisLe200WithoutBearbeitungstatus) /
            aggCountGesamt) *
            100
        );
        return {
          toString: () => roundedKpiNachBearbeitung + '%',
          aggCountAccepted,
          aggCountVerfuegbarkeitGeschaffen,
          aggCountAufpreisLe200WithoutBearbeitungstatus,
          aggCountGesamt,
        };
      }
    },
    headerComponentParams: {
      withBreakpoints: true,
    },
    valueFormatter: params =>
      !isNaN(params.value.kpiNachBearbeitung) ? params.value.kpiNachBearbeitung + '%' : null,
    valueGetter: params => params.data,
  },
  { sortable: true, field: 'countNichtVerfuegbar', aggFunc: 'sum', headerName: 'N', width: 70 },
  { sortable: true, field: 'countAufAnfrage', aggFunc: 'sum', headerName: 'RQ', width: 70 },
  { sortable: true, field: 'countVerfuegbarGt200', aggFunc: 'sum', headerName: 'J >= 200', width: 120 },
  {
    sortable: true,
    field: 'countAccepted',
    headerName: 'Akzeptiert',
    width: 135,
    aggFunc: params => {
      // if second level of grouping
      if (
        params.values.length > 0 &&
        params.values[0].hasOwnProperty('total') &&
        params.values[0].hasOwnProperty('totalAkzeptiert')
      ) {
        const totalAkzeptiert = params.values.reduce((acc, curr) => acc + curr.totalAkzeptiert, 0);
        const total = params.values.reduce((acc, curr) => acc + curr.total, 0);
        const percentAkzeptiert = Math.round((totalAkzeptiert / total) * 100) || 0;
        return {
          toString: () => totalAkzeptiert + ' (' + percentAkzeptiert + '%)',
          totalAkzeptiert,
          total,
        };
      }
      // if first level of grouping
      else {
        const totalAkzeptiert = params.values.reduce((acc, curr) => acc + curr.countAkzeptiert, 0);
        const total = params.values.reduce(
          (acc, curr) => acc + curr.countAufAnfrage + curr.countNichtVerfuegbar + curr.countVerfuegbarGt200,
          0
        );
        const percentAkzeptiert = Math.round((totalAkzeptiert / total) * 100) || 0;
        return {
          toString: () => totalAkzeptiert + ' (' + percentAkzeptiert + '%)',
          totalAkzeptiert,
          total,
        };
      }
    },
    valueGetter: params => ({
      countNichtVerfuegbar: params.data.countNichtVerfuegbar,
      countAufAnfrage: params.data.countAufAnfrage,
      countVerfuegbarGt200: params.data.countVerfuegbarGt200,
      countAkzeptiert: params.data.countAccepted,
    }),
    valueFormatter: params => {
      if (params.data) {
        const { countNichtVerfuegbar, countAufAnfrage, countVerfuegbarGt200, countAccepted } = params.data;
        const zuBearbeitenCount = countNichtVerfuegbar + countAufAnfrage + countVerfuegbarGt200;
        if (zuBearbeitenCount === 0) return countAccepted + ' (0%)';
        const percentVerfGeschaffen = Math.round((countAccepted / zuBearbeitenCount) * 100);
        return countAccepted + ' (' + percentVerfGeschaffen + '%)';
      } else params.value;
    },
  },
  {
    sortable: true,
    field: 'countVerfuegbarkeitGeschaffen',
    aggFunc: params => {
      // if second level of grouping
      if (
        params.values.length > 0 &&
        params.values[0].hasOwnProperty('total') &&
        params.values[0].hasOwnProperty('totalVerfGeschaffen')
      ) {
        const totalVerfGeschaffen = params.values.reduce((acc, curr) => acc + curr.totalVerfGeschaffen, 0);
        const total = params.values.reduce((acc, curr) => acc + curr.total, 0);
        const percentVerfGeschaffen = Math.round((totalVerfGeschaffen / total) * 100) || 0;
        return {
          toString: () => totalVerfGeschaffen + ' (' + percentVerfGeschaffen + '%)',
          totalVerfGeschaffen,
          total,
        };
      }
      // if first level of grouping
      else {
        const totalVerfGeschaffen = params.values.reduce(
          (acc, curr) => acc + curr.countVerfuegbarkeitGeschaffen,
          0
        );
        const total = params.values.reduce(
          (acc, curr) => acc + curr.countAufAnfrage + curr.countNichtVerfuegbar + curr.countVerfuegbarGt200,
          0
        );
        const percentVerfGeschaffen = Math.round((totalVerfGeschaffen / total) * 100) || 0;
        return {
          toString: () => totalVerfGeschaffen + ' (' + percentVerfGeschaffen + '%)',
          totalVerfGeschaffen,
          total,
        };
      }
    },
    headerName: 'Verf. geschaffen',
    valueGetter: params => ({
      countNichtVerfuegbar: params.data.countNichtVerfuegbar,
      countAufAnfrage: params.data.countAufAnfrage,
      countVerfuegbarGt200: params.data.countVerfuegbarGt200,
      countVerfuegbarkeitGeschaffen: params.data.countVerfuegbarkeitGeschaffen,
    }),
    valueFormatter: params => {
      if (params.data) {
        const { countNichtVerfuegbar, countAufAnfrage, countVerfuegbarGt200, countVerfuegbarkeitGeschaffen } =
          params.data;
        const zuBearbeitenCount = countNichtVerfuegbar + countAufAnfrage + countVerfuegbarGt200;
        if (zuBearbeitenCount === 0) return countVerfuegbarkeitGeschaffen + ' (0%)';
        const percentVerfGeschaffen = Math.round((countVerfuegbarkeitGeschaffen / zuBearbeitenCount) * 100);
        return countVerfuegbarkeitGeschaffen + ' (' + percentVerfGeschaffen + '%)';
      } else params.value;
    },
    headerComponentParams: {
      withBreakpoints: true,
    },
    width: 145,
  },
  {
    sortable: true,
    field: 'countVerfuegbarkeitNichtGeschaffen',
    aggFunc: 'sum',
    headerName: 'Verf. nicht geschaffen',
    headerComponentParams: {
      withBreakpoints: true,
    },
    width: 140,
  },
  {
    sortable: true,
    field: 'countNotEdited',
    aggFunc: 'sum',
    headerName: 'Nicht bearbeitet',
    headerComponentParams: {
      withBreakpoints: true,
    },
    width: 140,
  },
  {
    sortable: true,
    field: 'countEdited',
    aggFunc: 'sum',
    headerName: 'Bearbeitet',
    cellRenderer: params => '<b>' + params.value + '</b>',
    headerComponentParams: {
      bold: true,
    },
    width: 140,
  },
  {
    sortable: true,
    field: 'countGesamt',
    aggFunc: 'sum',
    cellRenderer: params => '<b>' + params.value + '</b>',
    headerComponentParams: {
      bold: true,
    },
    headerName: 'Gesamt',
    width: 115,
  },
  {
    field: 'conversionsOfReise.rate',
    headerName: 'Conversion Rate',
    width: 130,
    valueFormatter: params => (!isNaN(params.value) ? Math.round(params.value * 100) / 100 + '%' : '-'),
  },
  {
    field: 'conversionsOfReise.total',
    headerName: 'Opportunities Gesamt',
    width: 170,
    valueFormatter: params => (!isNaN(params.value) ? params.value : '-'),
  },
];
